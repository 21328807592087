<template>
    <div class="w-full">
      <label class="vs-input--label">Serviço</label>
      <v-select
        v-model="service"
        :multiple="multiple"
        :clearable="false"
        :filterable="true"
        :reduce="(option) => option.value"
        :options="allOptions"
        data-vv-as="Serviço"
        :appendToBody="appendToBody"
        class="fix-vselect"
        :autoscroll="false"
        v-validate.initial="'required'"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
    </div>
</template>

<script>
// Store Module

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    useId: {
      default: false
    },
    multiple: {
      default: false
    },
    appendToBody: {},
    disabled: {}
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    allOptions () {
      return this.$store.state.service.services.map(e => ({value: e.id, label: e.service}))
    },
    allServices () {
      return this.$store.state.service.services
    },
    service: {
      get () {
        // o v-model pode ser field ID ou Object
        if (this.useId) { // foi passado um ID
          return this.value
        } else {
          if (Array.isArray(this.value) && this.value.length) {
            return [...new Set(this.value.map(e => e.id))]
          }
          return this.value && this.value.id ? this.value.id : null
        }


      },
      set (value) {
        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.allServices.filter(e => value.includes(e.id)))
          } else {
            this.$emit('input', this.allServices.find(e => e.id === value))
          }
        }
      }
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    }
  },
  created () {
    // obter servicos
    if (!this.allServices.length) {
      this.$store.dispatch('service/fetchServices')
    }
  }
}
</script>
