var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2" },
        [
          _c("select-type-payment", {
            attrs: { appendToBody: _vm.appendToBody },
            model: {
              value: _vm.typePayment,
              callback: function ($$v) {
                _vm.typePayment = $$v
              },
              expression: "typePayment",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2" },
        [
          _c("select-account", {
            ref: "account",
            attrs: {
              disabled: !_vm.typePayment,
              filter: { typePayment: _vm.typePayment.id },
              appendToBody: _vm.appendToBody,
            },
            model: {
              value: _vm.account,
              callback: function ($$v) {
                _vm.account = $$v
              },
              expression: "account",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }